.pageWrapper {
  min-height: 100vh;
}

.contentArea {
  flex-grow: 1;
  max-width: 1300px;
  margin: 0 auto;

  &.fixedTopbar {
    padding-top: 55px;
  }
}

.fix-header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 5;

  .logo {
    height: 40px;
  }

  .navbar-brand {
    padding: 0;
  }
}

.carousel {
  .carousel-item {
    min-height: 400px;

    .carousel-caption {
      margin-top: 1.25rem;
      margin-bottom: 1.25rem;
      padding: 0;
      background: rgba(53, 54, 59, 0.65);
      border-radius: 25px;
      right: 25%;
      left: 25%;
      display: block !important;

      h3 {
        margin-top: 1rem;
      }

      p {
        margin-bottom: 1rem;
      }
    }

    img {
      min-width: 100% !important;
      min-height: 100% !important;
    }
  }
}

.items {
  display: table;
  width: 100%;

  .item {
    display: table-cell;
    margin-bottom: 1.5rem;

    .card {
      height: 100%;
      padding-bottom: 38px;

      .image {
        aspect-ratio: 1.3;
        overflow: hidden;

        img {
          object-fit: contain;
          height: 100%;
          object-position: top;
        }
      }

      .card-body {
        position: relative;

        .btn {
          position: absolute;
          bottom: -18px;
        }
      }
    }
  }
}

.not-found {
  min-height: 450px;

  .card {
    background-position: bottom;
    background-size: cover;

    .card-title {
      position: absolute;
      background: rgba(255, 255, 255, 0.75);
      border-radius: 25px;
      top: 2rem;
      border: 2px solid white !important;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      width: 150px;
    }

    .card-body {
      position: absolute;
      background: rgba(255, 255, 255, 0.75);
      border-radius: 25px;
      border: 2px solid white !important;
      left: 0;
      right: 0;
      bottom: 25%;
      margin-left: 10%;
      margin-right: auto;
      width: 275px;
      text-align: center;

      a {
        border: 2px solid black;
        display: inline;
        padding: 0 8px;
      }
    }
  }
}